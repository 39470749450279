export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [];

export const dictionary = {
		"/(private)": [7,[2],[3]],
		"/(private)/actions": [11,[2],[3]],
		"/(public)/auth/complete": [~33,[4],[5]],
		"/(public)/change-password": [34,[4],[5]],
		"/(private)/chatbot": [12,[2],[3]],
		"/(private)/config": [13,[2],[3]],
		"/(private)/documentation": [14,[2],[3]],
		"/(private)/findings": [15,[2],[3]],
		"/(public)/forgot-password": [35,[4],[5]],
		"/(private)/globalsearch": [16,[2],[3]],
		"/(private)/graph": [17,[2],[3]],
		"/(private)/input": [18,[2],[3]],
		"/(private)/input/edit": [19,[2],[3]],
		"/(private)/input/new": [20,[2],[3]],
		"/(private)/jobs": [21,[2],[3]],
		"/(public)/login": [36,[4],[5]],
		"/(public)/mfa-verification": [37,[4],[5]],
		"/(private)/reports": [22,[2],[3]],
		"/(private)/settings": [23,[2],[3]],
		"/(private)/(admin)/team": [8,[2],[3]],
		"/(private)/(admin)/team/new": [10,[2],[3]],
		"/(private)/(admin)/team/[id]": [9,[2],[3]],
		"/(private)/threatmodeling": [24,[2],[3]],
		"/(private)/threatmodeling/heatmap": [25,[2],[3]],
		"/(private)/threatmodeling/manageassessments": [26,[2],[3]],
		"/(private)/threatmodeling/mitigationslib": [28,[2],[3]],
		"/(private)/threatmodeling/mitigations": [27,[2],[3]],
		"/(private)/threatmodeling/threatactorslib": [30,[2],[3]],
		"/(private)/threatmodeling/threatactors": [29,[2],[3]],
		"/(private)/threatmodeling/ttpslib": [31,[2],[3]],
		"/(private)/threatmodeling/visibilityevaluation": [32,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';